<template>
  <v-card>
    <v-toolbar card prominent color="primary" dark>
      <v-toolbar-title>Project Listing</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="projects"
        :expand="expand"
        item-key="_id.projectName"
      >
        <template slot="items" slot-scope="props">
          <tr @click="props.expanded = !props.expanded">
            <td>{{ props.item._id.projectName }}</td>
            <td>{{ props.item.countries }}</td>
            <td>{{ props.item.sampleSize }}</td>
            <td>{{ props.item.status }}</td>
          </tr>
        </template>
        <template slot="expand">
          <v-card>
            <v-card-text>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { DataService } from "./../services/BuyerDataService";

const dataService = new DataService();

export default {
  data () {
    return {
      expand: false,
      headers: [
        { text: 'Project Name', sortable: false, value: 'projectName' },
        { text: 'Countries', value: 'countries' },
        { text: 'Sample Size', value: 'sampleSize' },
        { text: 'Status', value: 'status' },
      ],
      projects: [
        // {
        //   name: 'Frozen Yogurt',
        //   countries: "Malaysia, Singapore",
        //   sampleSize: 500,
        //   status: "Open
        // }
      ]
    }
  },
  methods: {
    getProjects() {
      dataService.getProjects().then(r => {
        this.projects = r;
      });
    }
  },
  mounted() {
    this.getProjects()
  }
}
</script>